import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../styles/core.css"
import "../../styles/styles.css"
import Layout from "../../components/Layout"
import MenuOne from "../../components/MenuOne"
import MenuTwo from "../../components/MenuTwo"
import Gallery from "../../components/Gallery"
import Content from "../../components/Content"

const galImgs = ['/page-images/house_2.jpg', '/page-images/braniel-3601_1.jpg', '/page-images/vivomed_2.jpg', '/page-images/crockard_3.jpg', '/page-images/hilary-mckee_4.jpg', '/page-images/braniel-3613_0.jpg', '/page-images/reduced_2.jpg', '/page-images/anchor_watch_apartments_3.jpg', '/page-images/deecontemp_2.jpg', '/page-images/5_2.jpg', '/page-images/donaghadee_6_3.jpg']

export default function Home() {
  return (
  <Layout breadcrumbs={["Our work"]}>
    <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content>
<p><strong>Our Work</strong></p>
<p>	The diverse nature of the projects undertaken to date by our staff both in this practice and through previous positions within the profession, has given us the breadth of experience and ability to work on a wide range of schemes from small alterations to multi-million pounds projects, which include domestic, residential, commercial, community, leisure and industrial buildings.</p>

</Content>

    <div className="region region-content-bottom">
        <MenuOne />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
    </div>

  </Layout>

  )
}
